import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for Windows",
  "path": "/Software/Windows/InstarVision/Record/Continuous_Recording/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "Program regular video recording for your cameras.",
  "image": "../../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='InstarVision v3 for Windows' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/InstarVision/Record/Continuous_Recording/' locationFR='/fr/Software/Windows/InstarVision/Record/Continuous_Recording/' crumbLabel="Continuous Recording" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0297ec3d95ab04733625bc8830664e0d/29114/Banner_en-InstarVision2-0_Record.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAeklEQVQI143HsQ6CMBhF4f9V9Pkc2DSwO9A61bAQJngVF9YONPEBjCsFSWnIMRHjaLjJl9wjLAEmvwojvPr1E9k6YwynNIM4Icp6tB3Qbka7+KPcjOrCX3kXuNwXsrPmmBzIrUekeiLlA6l7pPFI/dVsNbC/3tgV7aff3kvA79KvXEkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/e4706/Banner_en-InstarVision2-0_Record.avif 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/d1af7/Banner_en-InstarVision2-0_Record.avif 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/7f308/Banner_en-InstarVision2-0_Record.avif 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/e1c99/Banner_en-InstarVision2-0_Record.avif 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/76ea5/Banner_en-InstarVision2-0_Record.avif 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/f696c/Banner_en-InstarVision2-0_Record.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/a0b58/Banner_en-InstarVision2-0_Record.webp 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/bc10c/Banner_en-InstarVision2-0_Record.webp 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/966d8/Banner_en-InstarVision2-0_Record.webp 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/445df/Banner_en-InstarVision2-0_Record.webp 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/78de1/Banner_en-InstarVision2-0_Record.webp 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/882b9/Banner_en-InstarVision2-0_Record.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/81c8e/Banner_en-InstarVision2-0_Record.png 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/08a84/Banner_en-InstarVision2-0_Record.png 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/c0255/Banner_en-InstarVision2-0_Record.png 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/b1001/Banner_en-InstarVision2-0_Record.png 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/161ec/Banner_en-InstarVision2-0_Record.png 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/29114/Banner_en-InstarVision2-0_Record.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0297ec3d95ab04733625bc8830664e0d/c0255/Banner_en-InstarVision2-0_Record.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "side-navigation---recording-schedule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---recording-schedule",
        "aria-label": "side navigation   recording schedule permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - Recording Schedule`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5deddc8792ee656a5358a7227754ce46/8de58/en_record_permanentrecording.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAADpElEQVQ4yx3Me1eTBQDA4fdz1L+esmPBJLYxcIwENuZwzLHUMbBwXhh3Z22rTWwIctmIEWNQMYiYJhYXrSTNW3FMKDioZZrYTUQFYgyEIkB+nXw+wCOkqtJ5WZKARJaEVKYgTpaEKFbOhmgZMbFxxEoSeEkkZkNUDKIYMVKZDIlUgkQaR1RMPOvWR9Hb00dvdzf9Z84gtBy7xLGeUTpP/kDLB/20hwbxd4ziaR3BH7xMZ/cVTl+4hq3iOHWt/VweGWPox7t8N3yLcwO36ewdpPOTHoJtQT7uDCEUFpeSrs3BZLKi1eexJWMXxjw3vhOj1Ae+pKz6fSo8DVTWBSj3+Kms8VPmOELZoVqaGoN4m0J4vA0Emxtp8b+H4KuvYZ/TjcXpZn9ZDTmuSl6r/pCSmo+w2Rz4qg5QYcvBWZrLYVsRtS4Hh0us5L9u5sBuIzaTmkBXN29/e5eai9cRfK0N1LY3c7S9GVfAR+G7dTgam/jplzHujI0xNTnB7PQD5sNTTE8+YnJyivHx+7T53IQ8JbS7cjg1NEL9BITuLSGYCzLJyEpGm6VkqymFTNMWLLtf5eG1syzMhZl/vEh4NsLc4wUic3NEInPcu/c7jgItxVkJ7NW+SOtXX1P/5xrBO7MIqjQtcrkCZaoSZUoqapUKg8HAwz/GWIiEmZmZ4cHEBPOzYWamp4mEZxgfn2C9WMMzLyTx7Lp43CcvUvbrE7w/RxBu/DbD97cfMXLnIeOza5wdGCEQ7CD8zzKRFVh9ssa/K8vMra6xsLzC/MoK0wvL7K38huyqGxiP3uLT4SnO/7XEpQeLCLX1DeTs2YvZUsAbLjeHKr3st1jwf3GRd/qHuHBlkLNXRrGdHsF/6Sbl529ypO8qCl0ecoMNddFxBm/c53/LS0sIOp2azckKNFvVJKfI2abXYNieQYZuC8Yd25BKRURL4tko2YRYnkaCUk+8MpM4lQGZUsdzscmcu3z1abi4+DeCKlVBnjmXbNN2skw72LlTj06nwWDIYIfRgEa9mfhNG4lLECEWb0AU/TwyWTSJiTISX0lCvjmF4dHrT8PV1VUEvT6NdJWCYstuzLuMbFMno0+Vo0lLQalMIr/ISnmgn4O+8xR7PqfQWY05bw/5FjNvWgvJ3ldEqKuXocGrDAwMIHiqXBx2WXHaCrEW5eK0H8BemofNmo/TUUJFuZvyug6c3hAObxeOus84WNFGqb0cu/0tbHYHwbZ2TvX10XXiBP8Bf6OrefG4p9cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5deddc8792ee656a5358a7227754ce46/e4706/en_record_permanentrecording.avif 230w", "/en/static/5deddc8792ee656a5358a7227754ce46/d1af7/en_record_permanentrecording.avif 460w", "/en/static/5deddc8792ee656a5358a7227754ce46/7f308/en_record_permanentrecording.avif 920w", "/en/static/5deddc8792ee656a5358a7227754ce46/8ce75/en_record_permanentrecording.avif 1219w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5deddc8792ee656a5358a7227754ce46/a0b58/en_record_permanentrecording.webp 230w", "/en/static/5deddc8792ee656a5358a7227754ce46/bc10c/en_record_permanentrecording.webp 460w", "/en/static/5deddc8792ee656a5358a7227754ce46/966d8/en_record_permanentrecording.webp 920w", "/en/static/5deddc8792ee656a5358a7227754ce46/4fba2/en_record_permanentrecording.webp 1219w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5deddc8792ee656a5358a7227754ce46/81c8e/en_record_permanentrecording.png 230w", "/en/static/5deddc8792ee656a5358a7227754ce46/08a84/en_record_permanentrecording.png 460w", "/en/static/5deddc8792ee656a5358a7227754ce46/c0255/en_record_permanentrecording.png 920w", "/en/static/5deddc8792ee656a5358a7227754ce46/8de58/en_record_permanentrecording.png 1219w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5deddc8792ee656a5358a7227754ce46/c0255/en_record_permanentrecording.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select your camera - or keep `}<strong parentName="p">{`All Channels`}</strong>{` selected to set all your camera´s at once - and check `}<strong parentName="p">{`Activate Recording`}</strong>{` to start a continuous recording within the selected time window. Clicking on `}<strong parentName="p">{`Default`}</strong>{` will activate a 24/7 recording schedule. You can adjust this schedule by first selecting the day of the week, you want to change, and then select a time window below. You can either select two time windows - e.g. from 12 at night to 7am and from 6pm to 11:59pm to keep an eye on your office over the off-hours. Or hide the second time line to cover a continuous time period. Please make sure, that the first time value always has to be earlier then the second and the entire time window has to be contained by the 24h day - e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Time Segment 1 08:00:00AM - 06:00:00AM`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Time Segment 2 08:00:00PM - 06:00:00AM`}</code>{` would `}<strong parentName="p">{`NOT`}</strong>{` be valid entries.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      